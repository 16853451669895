import React from "react"
import styled from "styled-components"

Button.defaultProps = {
  padding: "padding-bottom-large",
}

function Button(props) {
  const { children, padding, className } = props

  return (
    <StyledBoxedContainer
      className={`container boxed-width ${padding} ${className}`}
    >
      {children}
    </StyledBoxedContainer>
  )
}

export default Button

// Styles
const StyledBoxedContainer = styled.div`
  &.align-start {
    align-items: flex-start;
  }
`
