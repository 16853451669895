import React from "react"
import styled from "styled-components"
import Mailchimp from "react-mailchimp-form"

function EmailSignup(props) {
  return (
    <EmailContainer className="container">
      <SignupCtaContent className="col-5 col-7-d">
        <h2>Thousands of hours of design knowledge.</h2>
        <SignupParagraph>
          We upload new snippets on a regular basis. Sign up to get a nudge when
          they're released. We also announce new features too. No spam. Ever.
        </SignupParagraph>
        <EmailWrapper>
          <Mailchimp
            action="https://hotmail.us3.list-manage.com/subscribe/post?u=ac98385643a5c820cc83619c4&amp;id=ddb4fc0213"
            fields={[
              {
                name: "EMAIL",
                placeholder: "Email",
                type: "email",
                required: true,
              },
            ]}
            // Change predetermined language
            messages={{
              sending: "Sending... 🎁",
              success: "Thank you for subscribing! 🎉",
              error: "An unexpected internal error has occurred. 🤦‍♂️",
              empty: "You must enter an e-mail address. 💌",
              duplicate:
                "Too many subscribe attempts for this email address, cheeky. 🤙",
              button: "Sign up",
            }}
            // Add a personalized class
            className="mailchimp-form"
          />
        </EmailWrapper>
      </SignupCtaContent>
    </EmailContainer>
  )
}

export default EmailSignup

// Styles
const EmailContainer = styled.div`
  min-height: 360px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.lightbackground};
  border-radius: 8px;
  width: 100%;
  margin-top: 80px;
  text-align: left;
  box-sizing: border-box;
`

const SignupParagraph = styled.p``

const EmailWrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  form {
    font-size: 16px;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
  }

  input {
    border: none;
    font-size: 16px;
    width: auto;
    height: 40px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    border: solid 1px ${props => props.theme.colors.white};
    flex: 1;
    margin-right: 20px;
    font-family: "Avenir LT W01_65 Medium1475532", Avenir, Raleway,
      HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;

    @media (max-width: 768px) {
      margin-bottom: 24px;
      margin-right: 0;
    }

    ::placeholder {
      color: ${props => props.theme.colors.white};
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${props => props.theme.colors.white};
    }

    ::-ms-input-placeholder {
      color: ${props => props.theme.colors.white};
    }
  }

  button {
    border: none;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 4px;
    margin-left: auto;
    cursor: pointer;
    transition: all 240ms ease-in-out;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    height: 39px;

    background-color: ${props => props.theme.colors.orange};
    color: ${props => props.theme.colors.darkorange};
    border-color: ${props => props.theme.colors.white};
    border-radius: 8px;
    padding: 8px 20px;
    display: inline-flex;
    align-self: flex-start;

    &:hover {
      background-color: rgb(255, 255, 255, 80%);
      border-color: rgb(255, 255, 255, 80%);
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .msg-alert {
    width: 100%;
    font-size: 12px;
    position: static;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 24px;
    position: absolute;
    top: 24px;

    p {
      margin-bottom: 0;
      color: #fff !important;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
  }
`

const SignupCtaContent = styled.div`
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 100px;

  ${props => props.theme.query.tablet} {
  }
`
